import { ReactNode, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const AuthGuard = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkStatus) {
      navigate("/");
    }
  }, []);

  const checkStatus = useMemo(() => localStorage.getItem("token") ?? "", []);

  return <> {children} </>;
};
export default AuthGuard;
