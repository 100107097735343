import React, { lazy, useEffect, useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Loader from "./core/components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { tokenUserData } from "./store/auth/authAction";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "../src/core/theme";

const ProjectCatalogue = lazy(() => import("./screen/Admin/ProjectCatalogue"));
const ToggleLogs = lazy(() => import("./screen/Admin/ToggleLogs"));
const AdminRoute = lazy(() => import("./screen/adminRoute"));
const UserRoute = lazy(() => import("./screen/userRoute"));
const Login = lazy(() => import("./screen/Auth/login"));
const ExtraStaffing = lazy(() => import("./screen/Admin/ExtraStaffing"));
const Organization = lazy(() => import("./screen/Admin/Organization"));
const Notfound = lazy(() => import("./screen/NotFound"));
const UserDashboard = lazy(() => import("./screen/user/Dashboard"));
const AdminDashboard = lazy(() => import("./screen/Admin/AdminDashboard"));
const EmplSalaryManagement = lazy(
  () => import("./screen/Admin/EmplSalaryManagement")
);
const ForgotPassword = lazy(() => import("./screen/Auth/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
  () => import("./screen/Auth/ForgotPasswordSubmit")
);
const Tag = lazy(() => import("./screen/user/tag"));
const Toggle = lazy(() => import("./screen/user/Toggle"));
const UserLogsDetails = lazy(() => import("./screen/user/UserLogsDetails"));
const UserLogsRequest = lazy(() => import("./screen/user/UserLogRequest"));
const UserSalaryDetails = lazy(() => import("./screen/user/UserSalaryDetails"));
const UserManagement = lazy(() => import("./screen/Admin/UserDataMangment"));
const UserLogsManagement = lazy(
  () => import("./screen/Admin/UserLogManagement")
);
const UserLogComponent = lazy(() => import("./screen/Admin/UserLogComponent"));
const UserLogApproval = lazy(() => import("./screen/Admin/UserLogApproval"));
const UserLogsSettings = lazy(() => import("./screen/Admin/UserLogsSettings"));
const HolidayManagment = lazy(() => import("./screen/Admin/HolidayManagment"));
const ProjectManagement = lazy(
  () => import("./screen/Admin/ProjectManagement")
);
const CommonProfile = lazy(() => import("./screen/CommonProfile/Profile"));
const AdminLeaveComponent = lazy(() => import("./screen/Admin/LeaveMangment"));
const Attendance = lazy(() => import("./screen/Admin/Attendance"));
const UserAttendance = lazy(() => import("./screen/Admin/UserAttendance"));
const MetaNeoverce = lazy(() => import("./screen/Admin/MetaNeoverce"));

const AppRoutes = () => {
  const dispatch: any = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(
        tokenUserData({
          token: token,
        })
      );
    }
  }, [dispatch, token]);

  const { auth }: any = useSelector((state) => state);
  const theme = useMemo(
    () => createTheme(auth?.mode as "dark" | "light"),
    [auth?.mode]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <React.Suspense fallback={<Loader />}>
        <Routes>
          {/* AUTH_ROUTE */}
          <Route path="/" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/forgotPassword/forgotPasswordsubmit"
            element={<ForgotPasswordSubmit />}
          />
          <Route path="404" element={<Notfound />} />
          <Route path="*" element={<Navigate to={`/404`} replace />} />
          {/* ADMIN_ROUTE */}
          <Route
            path="admin/dashboard"
            element={<AdminRoute component={AdminDashboard} />}
          />
          <Route
            path="admin/userlog"
            element={<AdminRoute component={UserLogComponent} />}
          />
          <Route
            path="admin/userlogapproval"
            element={<AdminRoute component={UserLogApproval} />}
          />
          <Route
            path="admin/logsettings"
            element={<AdminRoute component={UserLogsSettings} />}
          />
          <Route
            path="admin/userlogupdate/:id"
            element={<AdminRoute component={UserLogsManagement} />}
          />
          <Route
            path="admin/usermanagement"
            element={<AdminRoute component={UserManagement} />}
          />
          <Route path="hr/toggle" element={<AdminRoute component={Toggle} />} />
          <Route
            path="admin/holidaymanagment"
            element={<AdminRoute component={HolidayManagment} />}
          />
          <Route
            path="admin/profile"
            element={<AdminRoute component={CommonProfile} />}
          />
          <Route
            path="admin/leavemanagement"
            element={<AdminRoute component={AdminLeaveComponent} />}
          />
          <Route
            path="admin/attendance"
            element={<AdminRoute component={Attendance} />}
          />
          <Route
            path="admin/attendance/:id"
            element={<AdminRoute component={UserAttendance} />}
          />
          <Route
            path="admin/projects"
            element={<AdminRoute component={ProjectManagement} />}
          />
          <Route
            path="admin/extrastaffing"
            element={<AdminRoute component={ExtraStaffing} />}
          />
          <Route
            path="admin/salarymanagement"
            element={<AdminRoute component={EmplSalaryManagement} />}
          />
          <Route
            path="admin/toggle"
            element={<AdminRoute component={ToggleLogs} />}
          />
          <Route
            path="admin/organization"
            element={<AdminRoute component={Organization} />}
          />
          <Route
            path="admin/projectcatalogue"
            element={<AdminRoute component={ProjectCatalogue} />}
          />
          <Route
            path="admin/meta-neoverce"
            element={<AdminRoute component={MetaNeoverce} />}
          />
          {/* USER_ROUTE */}
          <Route
            path="user/dashboard"
            element={<UserRoute component={UserDashboard} />}
          />
          <Route
            path="user/leavemanagement"
            element={<UserRoute component={AdminLeaveComponent} />}
          />
          <Route
            path="user/holidaymanagment"
            element={<UserRoute component={HolidayManagment} />}
          />
          <Route path="user/tag" element={<UserRoute component={Tag} />} />
          <Route
            path="user/toggle"
            element={<UserRoute component={Toggle} />}
          />
          <Route
            path="user/profile"
            element={<UserRoute component={CommonProfile} />}
          />
          <Route
            path="user/user-logs"
            element={<UserRoute component={UserLogsDetails} />}
          />
          <Route
            path="user/user-logs-request"
            element={<UserRoute component={UserLogsRequest} />}
          />
          <Route
            path="user/extrastaffing"
            element={<UserRoute component={ExtraStaffing} />}
          />
          <Route
            path="user/salary-details"
            element={<UserRoute component={UserSalaryDetails} />}
          />
        </Routes>
      </React.Suspense>
    </MuiThemeProvider>
  );
};

export default AppRoutes;
